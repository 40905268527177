
import { _Client } from '@/api-client';
import { AvailabilityLockPeriodInput } from '@/api-client/client';
import { FormInstance, RuleObject } from 'ant-design-vue/lib/form';
import { defineComponent, reactive, ref } from 'vue';
import dayjs, { Dayjs } from 'dayjs';
import { message } from 'ant-design-vue';

export default defineComponent({
    props: {
        onClose: {
            type: Function,
            required: true
        },
    },
    setup(props) {

        const formRef = ref<FormInstance>();
        const formModel = ref<AvailabilityLockPeriodInput>();
        const formState = reactive({
            lockAvailability:[]
        });
        const confirmLoading = ref(false);
        const lockAvailability = ref<[string,string]>()

        const rules = {
            lockAvailability: [
                {
                    validator: (rules: RuleObject, value: Dayjs[], callback: any) => {
                        if(value == null || value == undefined || value.length < 2){
                            return Promise.reject("请输入Availability Lock Period");
                        }
                        return Promise.resolve();
                    }, 
                    trigger: 'change'
                }
            ],
        }

        const close = () => props.onClose(); // 调用传递给组件的 onClose 方法

        const submit = () => {                                      
            formRef.value?.validate().then(() => {
                confirmLoading.value = true;
                _Client.availabilityLockPeriodClient.add(new AvailabilityLockPeriodInput({startTime: new Date(lockAvailability.value![0]), endTime: new Date(lockAvailability.value![1])})).then(() => {
                    message.success("success");
                    props.onClose(true);
                }).finally(() => confirmLoading.value = false);
            })
        }

        const changeDate = (dates: [Dayjs, Dayjs], dateStrings: [string, string]) => {
            lockAvailability.value = dateStrings;
        }

        return {
            formModel,
            formState,
            formRef,
            rules,
            confirmLoading,
            close,
            submit,
            changeDate,
        }
        
    },
})
