import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_range_picker = _resolveComponent("a-range-picker")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    visible: true,
    title: "Add Availability Lock Period",
    width: "calc(35%)",
    onCancel: _ctx.close,
    onOk: _ctx.submit,
    okText: "Save",
    confirmLoading: _ctx.confirmLoading,
    destroyOnClose: true,
    maskClosable: false
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_form, {
        ref: "formRef",
        model: _ctx.formState,
        rules: _ctx.rules
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_form_item, {
            label: "Availability Lock Period",
            name: "lockAvailability"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_range_picker, {
                onChange: _ctx.changeDate,
                value: _ctx.formState.lockAvailability,
                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formState.lockAvailability) = $event))
              }, null, 8, ["onChange", "value"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model", "rules"])
    ]),
    _: 1
  }, 8, ["onCancel", "onOk", "confirmLoading"]))
}